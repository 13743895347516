import { useState, useContext, useEffect } from "react";
import { Navbar, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHouse,
  faCartShopping,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { SearchContext } from "../../context/Searchcontext";
import axios from "axios";
import "./Header.scss";
import Logo from "../../Assets/Logo.png";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Sidebar from "./Sidebar";
import { Offcanvas, ListGroup } from "react-bootstrap";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [dropdownVisibleId, setDropdownVisibleId] = useState(null);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [DropdownVisibleIds, setDropdownVisibleIds] = useState(null);
  const { setSearchResults } = useContext(SearchContext);
  const [cartCount, setCartCount] = useState(() => {
    return JSON.parse(localStorage.getItem("cart"))?.length || 0;
  });
  const navigate = useNavigate();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // For smaller screens

  const handleDropdownToggles = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setCartCount(JSON.parse(localStorage.getItem("cart"))?.length || 0);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleSearch = async (e) => {
    const searchText = e.target.value;
    if (searchText.trim() === "") {
      setSearchResults([]);
      return;
    }
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-product",
        { search: searchText }
      );
      setSearchResults(response.data.data);
    } catch (error) {
      console.error("An error occurred while searching:", error);
    }
  };

  const handleDropdownToggle = (id) => {
    setDropdownVisibleId(dropdownVisibleId === id ? null : id);
  };

  const handleMouseEnter = (id) => {
    setDropdownVisibleId(id);
  };

  const handleMouseLeave = () => {
    setDropdownVisibleId(null);
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest(".dropdown-container")) {
      setDropdownVisibleId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [selectedSize, setSelectedSize] = useState(null);
  const handleSizeSelection = async (size, frame) => {
    setSelectedSize(size); // Update selected size
    navigate(`/frame/${size}`, { state: { frame } });
    await framelist(size); // Pass size to framelist
  };

  const framelist = async (size) => {
    try {
      const params = {
        size: size, // Dynamically pass the selected size
      };
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-products",
        params
      );
      // Handle the response here
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching frame list:", error);
    }
  };
  const toggleDropdowns = (id) => {
    setDropdownVisibleIds(DropdownVisibleIds === id ? null : id);
  };

  console.log("dropdownVisibleIds", DropdownVisibleIds);

  return (
    <>
      <div className="sticky">
        <div className="container-fluid boss-header">
          <div className="navmainstyle">
            <Navbar expand="lg" className="boss-header container header">
              {/* FontAwesome Icon */}
              <FontAwesomeIcon
                className="d-block d-md-none me-4" // Increase me-3 to me-4 or me-5
                icon={faBars}
                style={{
                  color: "#ec1b04",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  padding: "15px", // Optional: Adds more spacing to the right
                }}
                onClick={handleMenuToggle}
              />

              {/* Offcanvas Menu */}
              <Offcanvas
                show={showMenu}
                onHide={handleMenuToggle}
                placement="start"
              >
                <Offcanvas.Header className="newlook-headings" closeButton>
                  <Offcanvas.Title>Newlook</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <ListGroup>
                    <ListGroup.Item action href="/" className="newlook-heading">
                      Home
                    </ListGroup.Item>
                    <div>
                      <ListGroup>
                        <ListGroup.Item
                          className="newlook-heading"
                          action
                          onClick={() => {
                            toggleDropdowns(2); // Toggle dropdown visibility
                          }}
                        >
                          Frame Collections
                        </ListGroup.Item>
                      </ListGroup>

                      {DropdownVisibleIds === 2 && (
                        <div className="">
                          {[
                            { id: "1", label: "4x4 Frame" },
                            { id: "2", label: "5x5 Frame" },
                            { id: "3", label: "A5 Frame" },
                            { id: "4", label: "A4 Frame" },
                            { id: "5", label: "A3 Frame" },
                          ].map(({ id, label }) => (
                            <p
                              key={id}
                              className="sizeframe"
                              onClick={() => handleSizeSelection(id, label)}
                            >
                              {label}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                    <div>
                      <ListGroup>
                        <ListGroup.Item
                          className="newlook-heading"
                          action
                          onClick={() => {
                            toggleDropdowns(3); // Toggle dropdown visibility
                          }}
                        >
                          Customized Products
                        </ListGroup.Item>
                      </ListGroup>

                      <div>
                        {DropdownVisibleIds === 3 && (
                          <div className="">
                            {[
                              { id: "7", label: "Customised Wallet" },
                              { id: "13", label: "Customised Mug" },
                              { id: "8", label: "UV or Acrylic" },
                              { id: "9", label: "Ring Album" },
                              {
                                id: "10",
                                label: "Polaroids (Clip, Fairy, Lights)",
                              },
                            ].map(({ id, label }) => (
                              <p
                                key={id}
                                className="sizeframe"
                                onClick={() => handleSizeSelection(id, label)}
                              >
                                {label}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <ListGroup.Item
                      className="newlook-heading"
                      action
                      href="/digitalphoto"
                    >
                      Digital Photo Merge
                    </ListGroup.Item>
                  </ListGroup>
                </Offcanvas.Body>
              </Offcanvas>
              <div
                className="sideicondiv d-none d-md-block"
                onClick={() => navigate("/")}
              >
                <FontAwesomeIcon
                  icon={faHouse}
                  style={{
                    color: "#ec0909",
                    marginRight: "10px",
                  }}
                  className="sideicon"
                />
              </div>

              <Navbar.Brand href="/" className="mx-auto">
                <img src={Logo} className="logostyle" alt="logo" />
              </Navbar.Brand>
              <div className="header-icons ml-auto">
                {/* <div className="sideicondiv" onClick={toggleSearch}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="sideicon" />
                </div> */}
                {/* <div className="sideicondiv">
                  <FontAwesomeIcon icon={faUser} className="sideicon" />
                </div> */}
                <div
                  className="sideicondiv"
                  onClick={() => navigate("/cart")}
                  style={{ padding: "15px" }} // Adjust value as needed
                >
                  <FontAwesomeIcon icon={faCartShopping} className="sideicon" />
                  {cartCount > 0 && (
                    <span className="badgestyle">{cartCount}</span>
                  )}
                </div>
              </div>
            </Navbar>
          </div>
        </div>
        <div className="maindivall">
          <div className="category-row">
            <div className="category-item">
              <div className="dropdown-container hovereffecthead categorysection">
                <a className="headline" href="/">
                  <p className="hovereffectheadsize">All Collections</p>
                </a>
              </div>
            </div>

            <div className="category-item">
              <div
                className="dropdown-container hovereffecthead categorysection"
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}
              >
                <a className="headline" onClick={() => handleDropdownToggle(2)}>
                  <p className="hovereffectheadsize">Frame Collections</p>
                </a>
                {dropdownVisibleId === 2 && (
                  <div className="dropdown-content">
                    {[
                      { id: "1", label: "4x4 Frame" },
                      { id: "2", label: "5x5 Frame" },
                      { id: "3", label: "A5 Frame" },
                      { id: "4", label: "A4 Frame" },
                      { id: "5", label: "A3 Frame" },
                      // { id: "6", label: "Digital Photo Merge" },
                      // { id: "7", label: "Customised Wallet" },
                      // { id: "8", label: "Gift Hamper Box" },
                      // { id: "9", label: "Ring Album" },
                      // { id: "10", label: "Poloroids (Clip, Fairy, Lights)" },
                      // { id: "11", label: "Nikkah Frames" },
                      // { id: "12", label: "Premium Frames" },
                    ].map(({ id, label }) => (
                      <p
                        key={id}
                        className="sizeframe"
                        onClick={() => handleSizeSelection(id, label)}
                      >
                        {label}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="category-item">
              <div
                className="dropdown-container hovereffecthead categorysection"
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}
              >
                <a
                  className="headline"
                  // href="/mug"
                  onClick={() => handleDropdownToggle(3)}
                >
                  <p className="hovereffectheadsize">Customized Products</p>
                </a>
                {dropdownVisibleId === 3 && (
                  <div className="dropdown-content">
                    {[
                      // { id: "6", label: "Digital Photo Merge" },
                      { id: "7", label: "Customised Wallet" },
                      { id: "13", label: "Customised Mug" },
                      { id: "8", label: "UV or Acrylic" },
                      { id: "9", label: "Ring Album" },
                      { id: "10", label: "Poloroids (Clip, Fairy, Lights)" },

                      // { id: "7", label: "Customised Wallet" },
                      // { id: "11", label: "Nikkah Frames" },
                      // { id: "12", label: "Premium Frames" },
                    ].map(({ id, label }) => (
                      <p
                        key={id}
                        className="sizeframe"
                        onClick={() => handleSizeSelection(id, label)}
                      >
                        {label}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="category-item">
              <div
                className="dropdown-container hovereffecthead categorysection"
                // id="6"
              >
                <a
                  href={`/digitalphoto`} // Use dynamic path instead of hardcoded ":6"
                  // className="sizeframe"
                  // onClick={() => handleSizeSelection(6, "Digital Photo Merge")}
                >
                  <p className="hovereffectheadsize">Digital Photo Merge</p>
                </a>
              </div>
            </div>

            {/* <div className="category-item">
              <div
                className="dropdown-container hovereffecthead categorysection"
                id="11"
              >
                <a href={`/nikkahframes`}>
                  <p className="hovereffectheadsize">Nikkah Frames</p>
                </a>
              </div>
            </div> */}
          </div>
        </div>

        {/* For smaller screens */}
        {/* <div className="category-menu-sm d-lg-none">
          <Dropdown show={isDropdownVisible} onToggle={handleDropdownToggles}>
            <Dropdown.Toggle
              variant="dark"
              id="category-dropdown"
              className="dropdown-button"
            >
              Categories
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/">All Collections</Dropdown.Item>
              <Dropdown.Item href="/frame-collections">
                Frame Collections
              </Dropdown.Item>
              <Dropdown.Item href="/custom-products">
                Customized Products
              </Dropdown.Item>
              <Dropdown.Item href="/digital-photo-merge">
                Digital Photo Merge
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}

        {/* {isSearchVisible && (
           <Form className="header-searchbox d-flex w-75">
           <Form.Control
             className="searcher"
             type="search"
             placeholder="Search"
             aria-label="Search"
             style={{ borderRadius: "8px" }}
             onChange={handleSearch}
           />
           <Button
             style={{
               width: "80px",
               borderRadius: "8px",
               backgroundColor: "black",
               border: "none",
             }}
           >
             <FontAwesomeIcon icon={faMagnifyingGlass} />
           </Button>
         </Form>
        )} */}
      </div>
    </>
  );
};

export default Header;
