import React, { useState, useEffect } from "react";
import "./Cart.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Image from "../../Assets/noimage.jpg";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Cart = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(() => {
    const savedProducts = localStorage.getItem("cart");
    return savedProducts ? JSON.parse(savedProducts) : [];
  });
console.log(products,"products");

  // edit -----------------
  // const [details, setDetails] = useState([]);

  // useEffect(() => {
  //   // Retrieve the product details from localStorage
  //   const productDetails = localStorage.getItem("productDatas");
  //   if (productDetails) {
  //     setDetails(JSON.parse(productDetails));
  //   }
  // }, []);
  // console.log(details,"detaiii");

  // useEffect(()=>{
  //   const productDetails=localStorage.getItem("productDatas");
  //   if(productDetails){
  //     setDetails(JSON.parse(productDetails))
  //   }
  // },[]);
  // console.log(details);
  //-----------

  const [totalAmount, setTotalAmount] = useState(() => {
    const savedProducts = localStorage.getItem("cart");
    const products = savedProducts ? JSON.parse(savedProducts) : [];
    return products.reduce(
      (total, product) => total + (product.price || 0) * product.quantity,
      0
    );
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(products));
    const newTotalAmount = products.reduce(
      (total, product) => total + (product.price || 0) * product.quantity,
      0
    );
    setTotalAmount(newTotalAmount);
    localStorage.setItem("cartCount", products.length);
    window.dispatchEvent(new Event("storage")); // Notify other tabs of changes
  }, [products]);

  const incrementQuantity = (p_id) => {
    setProducts(
      products.map((product) =>
        product.p_id === p_id
          ? { ...product, quantity: product.quantity + 1 }
          : product
      )
    );
  };

  const decrementQuantity = (p_id) => {
    setProducts(
      products.map((product) =>
        product.p_id === p_id && product.quantity > 1
          ? { ...product, quantity: product.quantity - 1 }
          : product
      )
    );
  };

  const removeProduct = (p_id) => {
    const filteredProducts = products.filter(
      (product) => product.p_id !== p_id
    );
    setProducts(filteredProducts);
  };

  const checkout = () => {
    navigate("/checkout", { state: { products, totalAmount } });
  };
  console.log(checkout);

  return (
    <div className="container mt-5 cartmain">
      <div className="d-flex justify-content-between align-items-center">
        <h1>Your cart</h1>
        <NavLink className="continue" to="/">
          <p>Continue shopping</p>
        </NavLink>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 mt-3 hidequa mb-5">
            <div className="underlines container"></div>
            <div className="row">
              {products.map((product) => (
                <div key={product.p_id} className="col-12 row">
                  <div className="col-6">
                    <h6 className="titlelist">PRODUCT</h6>
                    <div className="col-12">
                      <div className="d-flex mt-5 gap-4">
                        <div>
                          {(() => {
                            const imageUrl =
                              Array.isArray(product.image) &&
                              product.image.length > 0
                                ? `https://lunarsenterprises.com:3008/${product.image} ` 
                                : typeof product.image === "string" &&
                                  product.image.length > 0
                                ? `${product.image}`
                                : Image;

                            console.log(imageUrl);

                            return (
                              <img
                                src={`https://lunarsenterprises.com:3008/${product.image} ` }
                                className="cartimage"
                                
                                alt="check now"
                              />
                            );
                          })()}
                        </div>

                        <div>
                          <h5 className="name">{product.name}</h5>
                          <h5 className="name">
                            Rs. {(product.price || 0).toFixed(2)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <h6 className="titlelist">QUANTITY</h6>
                    <div className="quantity-controls gap-4 mt-5">
                      <div className="d-flex justify-content-between align-items-center m-2 quantitybox">
                        <button
                          className="btn decrement"
                          onClick={() => decrementQuantity(product.p_id)}
                        >
                          -
                        </button>
                        <span className="quantity">{product.quantity}</span>
                        <button
                          className="btn increment"
                          onClick={() => incrementQuantity(product.p_id)}
                        >
                          +
                        </button>
                      </div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => removeProduct(product.p_id)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <div className="col-2">
                    <h6 className="titlelistlast">TOTAL</h6>
                    <div className="total mt-5">
                      Rs. {(product.price * product.quantity).toFixed(2)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Mobile Responsive */}
          <div className="col-12 col-lg-12 hidesec mt-3 mb-5">
            <div className="underlines container"></div>
            <div className="row">
              {products?.map((product) => (
                <div key={product.p_id} className="row col-12">
                  <div className="col-10">
                    <h6 className="titlelist">PRODUCT</h6>
                    <div className="col-12">
                      <div className="mobileaddprice mt-5 col-11 gap-4">
                      <div>
                          {(() => {
                            const imageUrl =
                              Array.isArray(product.image) &&
                              product.image.length > 0
                                ? `https://lunarsenterprises.com:3008/${product.image}`
                                : typeof product.image === "string" &&
                                  product.image.length > 0
                                ? `${product.image}`
                                : Image;

                            console.log(imageUrl);

                            return (
                              <img
                                src={`https://lunarsenterprises.com:3008/${product.image}`}
                                className="cartimage"
                                alt="small screen"
                              />
                            );
                          })()}
                        </div>
                        <div>
                          <h5 className="name">{product.name}</h5>
                          <h5 className="name">
                            Rs. {(product.price || 0).toFixed(2)}
                          </h5>

                          <div className="quantity-controls gap-2 mt-5">
                            <div className="d-flex justify-content-between align-items-center quantitybox">
                              <div
                                className="btns decrement"
                                onClick={() => decrementQuantity(product.p_id)}
                              >
                                -
                              </div>
                              <span className="quantity">
                                {product.quantity}
                              </span>
                              <div
                                className="btns increment"
                                onClick={() => incrementQuantity(product.p_id)}
                              >
                                +
                              </div>
                            </div>
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ cursor: "pointer" }}
                              onClick={() => removeProduct(product.p_id)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-2">
                    <h6 className="titlelistlast">TOTAL</h6>
                    <div className="total mt-5">
                      Rs. {(product.price * product.quantity).toFixed(2)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="underlinesest container"></div>

      <div className="estimate mt-5">
        <h1>Estimated total Rs. {totalAmount.toFixed(2)}</h1>
      </div>

      <div className="estimate">
        <h2>
          Taxes, Discounts and <span className="underline-text">shipping</span>{" "}
          calculated at checkout
        </h2>
      </div>

      <div className="estimate mb-5" onClick={checkout}>
        <div className="checkoutmain bg-black">
          <h3 className="checkout">Check Out</h3>
        </div>
      </div>
    </div>
  );
};

export default Cart;
