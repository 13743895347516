import React, { useState } from "react";
// import { ToastContainer } from "react-toastify";
import ReactImageMagnify from "react-image-magnify";
import { Form, InputGroup, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faUser,
  faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "./digitalphoto.css"; // Assume custom CSS is used
import axios from "axios";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Digitalphoto = () => {
  const [activeColor, setActiveColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [count, setCount] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  // const [newComment, setNewComment] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [photomergeImages, setPhotomergeImages] = useState([]);
  const [mainimage, setMainimage] = useState();
  const [saveImage, setSaveImage] = useState();
  const [selectframeType, setSelectframeType] = useState();
  const [totalAmount, setTotalAmount] = useState();

  useEffect(() => {
    postPhotomergeImages();
  }, []);

  const [commentlist, setcommentlist] = useState([]);

  const CommentList = async () => {
    try {
      const Commentshow = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-comment",
        { size_id: "6" }
      );
      setcommentlist(Commentshow.data.list);
      console.log("Commentshow.data", Commentshow.data);
    } catch (error) {}
  };

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };
  const navigate = useNavigate();
  // const digitalbuynow=()=>{
  //   navigate("/checkout")
  // }

  const digitalbuynow = () => {
    if (!selectedCopyType) {
      toast.error("Please select Copy Type .");
    }
    if (!selectedSize) {
      toast.error("Please select Size before proceeding.");
     
    }
    if (!activePerson) {
      toast.error("Please select an option.");
      return; 
    }

    navigate("/checkout", {
      state: {
        product_type: "special_frame",
        framename: "Digital Photo Merge",
        image: saveImage,
        copy: selectedCopyType,
        selectedSize: selectedSize,
        selectframeType: selectframeType,
        totalDiscount: activePersonprice,
      },
    });
  };

  const handleCommentSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/add-comment",
        { comment: newComment, size_id: "6" }
      );
      if (response.data.result) {
        toast.success("Comment submitted successfully!");
        setNewComment("");
        CommentList(); // Reload comments
      } else {
        toast.error("Failed to submit comment.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const [selectedCopyType, setSelectedCopyType] = useState("");

  
  const handleFrameType = (e) => {
    setSelectframeType(e.target.value);
  };

  const productName = "Digital Photo Merge";
  // const stock = 10;
  // const totalPrice = 1000;
  // const totalDiscount = 800;
  // const final = 20;
  const productSizes = [
    { s_id: "A3", s_name: "A3" },
    { s_id: "A4", s_name: "A4" },
    { s_id: "A5", s_name: "A5" },
  ];

  // const productCopy = [
  //   {c_id:"1", c_name: "Soft Copy" },
  //   {c_id  c_name: "Frames" },
  //   {  c_name: "Soft Copy + Frames" },
  // ];
  const description = "High-quality material, Comfortable, Stylish";
  // const commentlist = [
  //   { c_comment: "Great product!", c_comment_replay: "Thank you!" },
  //   { c_comment: "Fast shipping.", c_comment_replay: "Glad to hear that!" },
  // ];
  const Viewapi = [
    { price: "500", persons: "1 person" },
    { price: "750", persons: "2 person" },
    { price: "1000", persons: "3 person" },
    { price: "1250", persons: "4 person" },
    { price: "1500", persons: "5 person" },
    { price: "1750", persons: "6 person" },
    { price: "2000", persons: "7 person" },
    { price: "2250", persons: "8 person" },
    { price: "2500", persons: "9 person" },
    { price: "2750", persons: "10 person" },
  ];

  const [frameprice, setframeprice] = useState(200);

  const handleCopyTypeChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCopyType(selectedValue);
  
    // Ensure that activePersonprice is treated as a number
    const currentPrice = parseInt(activePersonprice, 10); // Add base 10 for safe parsing
    
    if (selectedValue === "soft-copy-frames") {
      const totalprice = currentPrice + frameprice; // Add frameprice only if condition is met
      setActivePersonprice(totalprice);
    } else {
      setActivePersonprice(currentPrice); // Keep the existing price if condition isn't met
    }
  };

  const handleImageClick = (img) => {
    setMainimage(img.pm_image);
  };

  const handlepersonClick = (price) => {
    setActivePersonprice(price.price);
    setActivePerson(price.persons);
  };

 
  console.log(mainimage, "mainimage");
  const handleSizeChange = (e) => setSelectedSize(e.target.value);
  const decrement = () => setCount((prev) => Math.max(prev - 1, 1));
  const Increment = () => setCount((prev) => prev + 1);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) setImagePreview(URL.createObjectURL(file));
  };
  // const handleCommentChange = (e) => setNewComment(e.target.value);
  // const handleCommentSubmit = (e) => {
  //   e.preventDefault();
  //   if (newComment) {
  //     alert(`New Comment: ${newComment}`);
  //     setNewComment("");
  //   }
  // };

  const handleMultipleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews((prev) => [...prev, ...newPreviews]);
  };

  const [activePerson, setActivePerson] = useState(null); // To track the selected person option
  const [activePersonprice, setActivePersonprice] = useState(null); // To track the selected person option
  console.log(activePerson, "activePerson");

  //   const handleImageClick = (img) => {
  //     setActivePerson(img.persons); // Set active person when an option is clicked
  //   };

  const postPhotomergeImages = async (data) => {
    const url =
      "https://lunarsenterprises.com:3008/newlook/list/photomerge-images";

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json", // Use 'application/x-www-form-urlencoded' if the server expects form data
        },
      });

      const images = response.data.list;
      setPhotomergeImages(images);
      // Set the first image as the default main image if available
      if (images.length > 0 && !mainimage) {
        setMainimage(images[0].pm_image);
      }
    } catch (error) {
      console.error(
        "Error posting photomerge images:",
        error.response ? error.response.data : error.message
      );
    }
  };

  console.log(photomergeImages, "photomergeImages");

 

 
   
  

  return (
    <>
      {/* mainimage ? 'https://lunarsenterprises.com:3008/' + mainimage : 'https://lunarsenterprises.com:3008/ ' + photomergeImages[0].pm_image, */}
      <ToastContainer />
      <div className="container product-display mb-5">
        <div className="selected-product">
          <div className="product-images">
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: productName,
                  isFluidWidth: true,
                  src: "https://lunarsenterprises.com:3008/" + mainimage,
                },
                largeImage: {
                  src: "https://lunarsenterprises.com:3008/" + mainimage,
                  width: 1200,
                  height: 1800,
                },
              }}
            />
            <div className="sub-images">
              {photomergeImages.length > 0 ? (
                photomergeImages.map((img, index) => (
                  <div key={index}>
                    <img
                      src={`https://lunarsenterprises.com:3008/${img.pm_image}`}
                      alt={`Color: ${img.color}`}
                      className="sub-image"
                      onClick={() => handleImageClick(img)}
                    />
                  </div>
                ))
              ) : (
                <p>No images available</p>
              )}
            </div>
          </div>
          <div className="product-details">
            <div className="d-flex">
              <h1>{productName}</h1>
              {/* <h4 className="final-discount">-{final}%</h4> */}
            </div>
            {/* <h6 style={{ color: "brown" }}>
              (Stock Available: {stock - count})
            </h6> */}

            <div className="selected-product-price">
              {/* <del className="selected-price">
                <span>Rs</span>
                {totalPrice}
              </del> */}
              <div className="selected-discounted-price">
                <span>Rs</span>
                {activePersonprice ? activePersonprice : 500}
              </div>
            </div>
            <div className="color-selection">
              <h6>Variety Options</h6>
              <div className="colors">
                {Viewapi.map((price, index) => (
                  <div
                    key={index}
                    className="color-box"
                    onClick={() => handlepersonClick(price)}
                    style={{
                      cursor: "pointer",
                      borderColor:
                        activePerson === price.persons ? "red" : "black",
                    }}
                  >
                    {price.persons}
                  </div>
                ))}
              </div>
            </div>

            <div>
              <div className="color-selection">
                <h6>Select Product Size</h6>
                <InputGroup>
                  <Form.Select
                    onChange={handleSizeChange}
                    value={selectedSize}
                    required
                  >
                    <option value="">Select a size</option>
                    {productSizes.map((size) => (
                      <option key={size.s_id} value={size.s_id}>
                        {size.s_name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
                {selectedSize && <p>Selected Size: {selectedSize}</p>}
              </div>

              <div className="color-selection">
                <h6 className="mt-3">Select</h6>
                <Form.Select onChange={handleCopyTypeChange} className="ms-2">
                  <option value="">Select Copy</option>
                  <option value="soft-copy">Soft Copy</option>
                  <option value="soft-copy-frames">Soft Copy + Frames</option>
                  {/* <option value="frames">Frames</option> */}
                </Form.Select>
                {/* Existing Dropdown for Product Sizes */}
              </div>
              <div className="color-selection">
                <h6 className="mt-3">Select Type</h6>
                <Form.Select onChange={handleFrameType} className="ms-2">
                  <option value="">Select Type</option>
                  <option value="laminated">Laminated</option>
                  <option value="Glass">Glass</option>
                  {/* <option value="frames">Frames</option> */}
                </Form.Select>
                {/* Existing Dropdown for Product Sizes */}
              </div>
            </div>
            {/* <p>Quantity</p>
            <div className="counting-box">
              <button onClick={decrement}>
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <div className="count">{count}</div>
              <button onClick={Increment}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div> */}
            <div className="image-upload">
              <h6>Upload an Image</h6>
              <InputGroup>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </InputGroup>
              {imagePreview && (
                <div className="image-preview" style={{ marginTop: "20px" }}>
                  <h6>Image Preview:</h6>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: "300px",
                      maxHeight: "300px",
                      borderRadius: "5px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </div>
              )}
            </div>

            <div className="image-upload">
              <h6>Upload Multiple Images</h6>
              <InputGroup>
                <Form.Control
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleMultipleImageChange}
                />
              </InputGroup>
              {imagePreviews.length > 0 && (
                <div className="image-previews">
                  {imagePreviews.map((preview, index) => (
                    <img
                      key={index}
                      src={preview}
                      alt={`Preview ${index + 1}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "5px",
                        borderRadius: "5px",
                      }}
                    />
                  ))}
                </div>
              )}
            </div>

            {/* <button className="addtocart">Add to Cart</button> */}
            <button className="addtocart" onClick={digitalbuynow}>
              Buy Now
            </button>
          </div>
        </div>
        <div className="container describ">
          <Tabs
            defaultActiveKey="describe"
            id="product-tabs"
            className="custom-tabs"
          >
            <Tab
              eventKey="describe"
              title="Description"
              className="custom-tab-content"
            >
              <ul>
                {description.split(",").map((item, index) => (
                  <li key={index}>{item.trim()}</li>
                ))}
              </ul>
            </Tab>
            <Tab
              eventKey="Comments"
              title="Comments"
              className="custom-tab-content"
            >
              <div className="comment-section">
                <form onSubmit={handleCommentSubmit}>
                  <textarea
                    value={newComment}
                    onChange={handleCommentChange}
                    placeholder="Write a comment..."
                  ></textarea>
                  <button type="submit">Submit</button>
                </form>
              </div>
            </Tab>
            <Tab
              eventKey="Review"
              title="Review"
              className="custom-tab-content"
            >
              <ul>
                {commentlist.map((comment, index) => (
                  <li key={index}>
                    <div className="userside">
                      <FontAwesomeIcon icon={faUser} />
                      <h6 className="user">User:</h6>
                    </div>
                    <div className="comment">{comment.c_comment}</div>
                    <div className="adminside">
                      <FontAwesomeIcon
                        style={{ marginLeft: "15px" }}
                        icon={faReplyAll}
                      />
                      <h6 className="user-reply">Reply:</h6>
                    </div>
                    <div className="admin-comment">
                      {comment.c_comment_replay}
                    </div>
                  </li>
                ))}
              </ul>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Digitalphoto;
