import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Checkout.scss";
import Footer from "../Checkout/Footer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Image from "../../Assets/noimage.jpg";

const allStatesOfIndia = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];

const Checkout = () => {
  const { state } = useLocation();
  // const {size}=useParams()

  const {
    quantity,
    product_type,
    name,
    image,
    id,
    color,
    colorid,
    totalDiscount,
    imagePreview,
    selectedSize,
    copy,
    framename,
    selectframeType,
  } = state || {};
  console.log(product_type, "checkinggg");

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_name: "",
    // last_name: "",
    email: "",
    mobile: "",
    address: "",
    city: "",
    area: "",
    state: "",
    postalCode: "",
    save_address: 0,
    billing_address: "",
    // ip_address: "1",
    amount: "",
  });

  console.log(selectedSize, "selectedSize");

  const count = quantity;
  const [states, setStates] = useState(allStatesOfIndia);
  const [isChecked, setIsChecked] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [useShippingAsBilling, setUseShippingAsBilling] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [products, setProducts] = useState(
    location.state?.products || [
      {
        id,
        quantity,
        image,
        name,
        totalDiscount,
        color,
        product_type,
        imagePreview,
        selectedSize,
        copy,
        framename,
        selectframeType,
        
      },
    ]
  );
  console.log(products, "haiiii");

  const [totalAmount, setTotalAmount] = useState(
    location.state?.totalAmount || ""
  );

  const [showMessage, setShowMessage] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleUseShippingAsBillingChange = () => {
    setUseShippingAsBilling(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(value, checked, "gggggggg");

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const productDetails = products?.map((product) => ({
      product_id: product.id,
      quantity: JSON.stringify(product.quantity),
      color_id: JSON.stringify(colorid ? colorid : products[0].colorid),
      s_id: selectedSize,

      // image:imagePreview,
    }));

    console.log(productDetails);

    const formDataObj = new FormData();
    formDataObj.append("product_type", product_type);
    formDataObj.append("full_name", formData.full_name);

    formDataObj.append("email", formData.email);
    formDataObj.append("mobile", formData.mobile);
    formDataObj.append(
      "address",
      `${formData.address},${formData.area},${formData.city},${formData.state},${formData.postalCode}`
    );
    formDataObj.append("city", formData.city);
    formDataObj.append("state", formData.state);
    // formDataObj.append("area", formData.area); // Uncomment if needed
    formDataObj.append("save_address", formData.save_address);
    formDataObj.append(
      "billing_address",
      `${formData.address},${formData.area},${formData.city},${formData.state},${formData.postalCode}`
    );
    // formDataObj.append("ip_address", formData.ip_address); // Uncomment if needed
    formDataObj.append("product_details", JSON.stringify(productDetails)); // Convert objects/arrays to JSON
    formDataObj.append("amount", totalDiscount);
    formDataObj.append("payment_method", "Online");
    // formDataObj.append("image", imagePreview); // Uncomment and adjust if handling file upload

    try {
      console.log("Submitting form data:", formDataObj);
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/add/order",
        formDataObj
      );
      console.log("Form data submitted:", response.data);
      console.log(response.data.paymentLinkUrl);
      console.log(products);
      console.log(formDataObj);

      setProducts([]);
      setTotalAmount();
      // navigate("/thankyou");
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
      // navigate("/thankyou");

      console.log(
        response,
        "---------------------------------------------------------------------------"
      );
      if (response.data.result) {
        localStorage.removeItem("products");
        if (paymentMethod === "online" && response.data.paymentLinkUrl) {
          window.location.href = response.data.paymentLinkUrl;
        } else {
          navigate("/checkout");
        }
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row">
          <div className="col-lg-6 br">
            <>
              <div className="contact mt-5">
                {isLoading ? (
                  <>
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <h3>Contact</h3>
                    <div className="mt-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control p-3 rounded-3 mt-1"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        // required
                      />
                    </div>
                    <div className="form-check mt-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="subscribe"
                        checked={formData.subscribe}
                        onChange={handleChange}
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Email me with news and offers
                      </label>
                    </div>
                  </>
                )}
              </div>

              <div className="delivery mt-5">
                {isLoading ? (
                  <>
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <h3>Delivery</h3>
                    <div className="col-12">
                      <select
                        name="country"
                        className="form-control form-select mb-3 p-3 rounded-3"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="India">India</option>
                      </select>
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {isLoading ? (
                  <>
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 mt-3">
                      <input
                        type="text"
                        name="full_name"
                        className="form-control p-3 rounded-3 mt-1"
                        placeholder="Full Name"
                        value={formData.full_name}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12 col-md-6 mt-3">
                      <input
                        name="address"
                        type="text"
                        placeholder="House name/ building name"
                        className="form-control p-3 rounded-3"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                      <input
                        name="area"
                        type="text"
                        placeholder="Area/Colony"
                        className="form-control p-3 rounded-3"
                        value={formData.area}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12 col-md-4 mt-3">
                      <input
                        name="city"
                        type="text"
                        placeholder="City"
                        className="form-control p-3 rounded-3"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <select
                        name="state"
                        className="form-control form-select p-3 rounded-3"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      >
                        <option>Select State</option>

                        {states.map((state, index) => (
                          <option key={index}>{state}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <input
                        name="postalCode"
                        type="text"
                        placeholder="Pincode"
                        className="form-control p-3 rounded-3"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <input
                        type="tel"
                        name="mobile"
                        className="form-control p-3 rounded-3 mt-1"
                        placeholder="Phone"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="form-check mt-3">
                {isLoading ? (
                  <>
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="save_address"
                      checked={formData.save_address === 1}
                      // checked={formData.save_address}
                      onChange={handleChange}
                      id="flexCheckSaveInfo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckSaveInfo"
                    >
                      Save this information for next time
                    </label>
                  </>
                )}
              </div>

              {isLoading ? (
                <>
                  <div className="skeleton">
                    <div className="skeleton-text"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="shipping mt-5">
                    <h5>Shipping method</h5>
                  </div>

                  <div className="">
                    Enter your shipping address to view available shipping
                    methods.
                  </div>
                </>
              )}
              {/* Payment */}

              <div className="mt-5">
                <div className="payment">
                  {isLoading ? (
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                      <div className="skeleton-text"></div>
                    </div>
                  ) : (
                    <>
                      <h3>Payment</h3>
                      <p className="text-danger">
                        {" "}
                        *Only Online transaction Available
                      </p>
                      <p>All transactions are secure and encrypted.</p>
                    </>
                  )}
                </div>

                {paymentMethod === "cashOnDelivery" && (
                  <>
                    <h5 className="mt-4">Billing Address</h5>
                    <div className="box-border">
                      <div className="form-check mt-3 p-3 mb-3 bd">
                        <div className="mx-3 p-2 bd">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="billingOption"
                            id="same"
                            value="same"
                            checked={!useShippingAsBilling}
                            onChange={() => setUseShippingAsBilling(false)}
                          />
                          <label
                            className="form-check-label text-dark"
                            htmlFor="same"
                          >
                            Same as shipping address
                          </label>
                        </div>
                        <div className="mx-3 p-2 bd">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="billingOption"
                            id="flexCheckBillingAddressCOD"
                            checked={useShippingAsBilling}
                            onChange={() => setUseShippingAsBilling(true)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckBillingAddressCOD"
                          >
                            Use shipping address as billing address
                          </label>
                        </div>

                        {useShippingAsBilling && (
                          <div className="mb-3 mt-2">
                            <div className="col-12 mt-3">
                              <select
                                name="billingCountry"
                                className="form-control form-select mb-3 p-3 rounded-3"
                                value={formData.billingCountry || ""}
                                onChange={handleChange}
                                required
                              >
                                <option value="India">India</option>
                              </select>
                            </div>
                            <div className="row">
                              <div className="col-12 mt-3">
                                <input
                                  type="text"
                                  name="full_name"
                                  className="form-control p-3 rounded-3 mt-1"
                                  placeholder="Full Name"
                                  value={formData.full_name}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-12 col-md-6 mt-3">
                                <input
                                  name="address"
                                  type="text"
                                  placeholder="Area/Colony"
                                  className="form-control p-3 rounded-3"
                                  value={formData.save_address}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-12 col-md-6 mt-3">
                                <input
                                  name="billing_address"
                                  type="text"
                                  placeholder="Building Address"
                                  className="form-control p-3 rounded-3"
                                  value={formData.billing_address}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-12 col-md-4 mt-3">
                                <input
                                  name="city"
                                  type="text"
                                  placeholder="City"
                                  className="form-control p-3 rounded-3"
                                  value={formData.city || ""}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-12 col-md-4 mt-3">
                                <select
                                  name="state"
                                  className="form-control form-select p-3 rounded-3"
                                  value={formData.state}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="kerala">kerala</option>
                                  {states.map((state) => (
                                    <option key={state.code} value={state.code}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-12 mt-3">
                                <input
                                  type="tel"
                                  name="mobile"
                                  className="form-control p-3 rounded-3 mt-1"
                                  placeholder="Phone"
                                  value={formData.mobile || ""}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* bcdscdbc starts here */}
              <div className="col-lg-6 mt-5 p-5" id="media-show">
                <div className="fixed">
                  {products?.map((data) => {
                    return (
                      <div className="d-flex" style={{ marginBottom: "20px" }}>
                        <div className="position-relative">
                          {isLoading ? (
                            <div className="skeleton">
                              <div className="skeleton-image"></div>
                            </div>
                          ) : (
                            <>
                              <div className="position-relative">
                                <img
                                  src={
                                    `https://lunarsenterprises.com:3008/${data.image}` ||
                                    (data.image && data.image.length > 0
                                      ? `https://lunarsenterprises.com:3008/${data.image[0].image}`
                                      : Image)
                                  }
                                  // alt="Product"
                                  className="img-fluid rounded"
                                  style={{ height: "50px", width: "50px" }}
                                />

                                {data.quantity > 0 && (
                                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                                    {data.quantity}
                                  </span>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        <div className="mx-3">
                          {isLoading ? (
                            <div className="skeleton">
                              <div className="skeleton-text"></div>
                              <div className="skeleton-text"></div>
                            </div>
                          ) : (
                            <>
                              <p className="order-name">{product_type==="regular_frame" ?data.name :data.framename}</p>

                              <p className="order fs-6"></p>
                              {color && <p>Color: {color}</p>}
                            </>
                          )}
                        </div>
                        <div className="ms-auto d-md-flex align-items-md-center">
                          {isLoading ? (
                            <div className="skeleton">
                              <div className="skeleton-text"></div>
                            </div>
                          ) : (
                            <span className="text-center amt">
                              ₹{totalDiscount} {data.price}
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  {isLoading ? (
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                      <div className="skeleton-text"></div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex mt-5">
                        <p className="fs-6 order">Subtotal</p>
                        <div className="ms-auto">
                          <span className="amt">
                            INR
                            {totalDiscount}
                            {totalAmount}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex">
                        <p className="fs-6 order">Shipping</p>
                        <div className="ms-auto text-end">
                          <span>Enter shipping address</span>
                        </div>
                      </div>

                      <div className="d-flex">
                        <h6>Total</h6>
                        <div className="ms-auto">
                          <span className="amt">
                            INR {totalDiscount}
                            {totalAmount}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* bcdscdbc ends here */}
              {isLoading ? (
                <div className="skeleton p-3">
                  <div className="skeleton-text"></div>
                </div>
              ) : (
                <div>
                  {paymentMethod === "cashOnDelivery" ? (
                    <div className="pay mt-5 mb-4 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        className="text-center btn fs-4 fw-semibold p-2"
                      >
                        Complete Order
                      </button>
                    </div>
                  ) : (
                    <div className="paynow-button mt-5 mb-4 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        className="text-center  text-white btn fs-4 fw-semibold p-2 w-100"
                      >
                        Pay now
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div className="bb-dot">
                <Footer />
              </div>
            </>
          </div>
          <div className="col-lg-6 mt-5 p-5" id="media-hide">
            <div className="fixed">
              {products.map((data, index) => {
                return (
                  <div
                    className="d-flex"
                    style={{ marginBottom: "20px" }}
                    key={index}
                  >
                    <div className="position-relative">
                      {isLoading ? (
                        <div className="skeleton">
                          <div className="skeleton-image"></div>
                        </div>
                      ) : (
                        <>
                          <div className="position-relative">
                            <img
                              src={
                                `https://lunarsenterprises.com:3008/${data.image}` ||
                                (data.image && data.image.length > 0
                                  ? `https://lunarsenterprises.com:3008/${data.image}`
                                  : Image)
                              }
                              // alt="Product"
                              className="img-fluid rounded"
                              style={{ height: "50px", width: "50px" }}
                            />
                            {data.quantity > 0 && (
                              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                                {data.quantity}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="mx-3">
                      {isLoading ? (
                        <div className="skeleton">
                          <div className="skeleton-text"></div>
                          <div className="skeleton-text"></div>
                        </div>
                      ) : (
                        <>
                         <p className="order-name">{product_type==="regular_frame" ?data.name :data.framename}</p>
                          <p className="order fs-6"></p>
                          {color && <p>Color: {color}</p>}
                        </>
                      )}
                    </div>

                    <div className="ms-auto d-md-flex align-items-md-center">
                      {isLoading ? (
                        <div className="skeleton">
                          <div className="skeleton-text"></div>
                        </div>
                      ) : (
                        <span className="text-center amt">
                          ₹{totalDiscount} {data.price}
                          {/* Make sure you have data.price defined */}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}

              {isLoading ? (
                <div className="skeleton">
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                </div>
              ) : (
                <>
                  <div className="d-flex mt-5">
                    <p className="fs-6 order">Subtotal</p>
                    <div className="ms-auto">
                      <span className="amt">
                        INR {totalDiscount}
                        {totalAmount}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex">
                    <p className="fs-6 order">Shipping</p>
                    <div className="ms-auto text-end">
                      <span>Enter shipping address</span>
                    </div>
                  </div>

                  <div className="d-flex">
                    <h6>Total</h6>
                    <div className="ms-auto">
                      <span className="amt">
                        INR {totalDiscount}
                        {totalAmount}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
      {showMessage && (
        <div
          className="success-message"
          style={{
            zIndex: "9999999",
            backgroundColor: "green",
            textAlign: "center",
            position: "fixed",
            top: "100px",
            left: "40%",
            width: "300px",
            height: "50px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Order Placed Successfully
        </div>
      )}
    </div>
  );
};

export default Checkout;
